import { Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import { useCallback } from "react";

declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}

// type storageData = {
//   date: number
//   minutes: string
//   seconds: string

// }

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useLocalStorage = <T,>(initialValue: T, key: string, fromStorage: Boolean = true): [T, SetValue<T>, (x: void) => void] => {
  // const firstMount = useRef(false);
  const getValue = useCallback((): T => {
    try {
      if (fromStorage) {
        const storage = localStorage.getItem(key);

        if (storage) {
          const data = JSON.parse(storage);

          if (data) return data;
          // if (data?.date && data.date - Date.parse(new Date().toString()) > 0) return data;

          // removeStorage();

          return initialValue;
        }
      }
      return initialValue;
    } catch (_) {
      return initialValue;
    }
  }, [initialValue, key, fromStorage]);

  const removeStorage = useCallback(() => {
    window.localStorage.removeItem(key);
    // setState(initialValue);
  }, []); //initialValue

  const [state, setState] = useState(getValue);

  useEffect(() => {
    // if (firstMount.current) localStorage.setItem(key, JSON.stringify(state));
    // else firstMount.current = true;
    if (!state) return;
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState, removeStorage];
};
