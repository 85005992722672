import React, { useCallback } from "react";

import { useRouter } from "next/router";
import { CARD_CREATE_PAYMENT, CARD_UPDATE_PAYMENT } from "../../../../../../graphql/mutations/mutationCard";
import { getPaymentUrl } from "../../../../../../utils/getNextUrl";
import { useTranslation } from "next-i18next";
import { SITE_URL } from "../../../../../../constants/APIKeys";
import { clsx } from "clsx";
import { useToggle } from "../../../../../../hooks/useToggle";
import { CardButton } from "../../../../../common/CardButton/CardButton";
import { useMutation } from "@apollo/client";
import { EVENT_CREATE_PAYMENT, EVENT_UPDATE_PAYMENT } from "../../../../../../graphql/mutations/mutationEvent";
import { Button } from "../../../../AddClub/components/common/Button";

const PayByOnline = ({ onResponse, isSubscription, options, familyProfileId, visitId, promocode, buttonTitle, clas = "" }) => {
  const router = useRouter();
  const {
    query: { club, eventId, cardId, templateId },
  } = router;
  const {
    i18n: { language },
  } = useTranslation();

  // console.log(templateId, cardId,visitId, "profileId");
  const mutation = !!isSubscription
    ? cardId
      ? CARD_UPDATE_PAYMENT
      : CARD_CREATE_PAYMENT
    : visitId
      ? EVENT_UPDATE_PAYMENT
      : EVENT_CREATE_PAYMENT;

  const [onlinePayment] = useMutation(mutation);
  const [disabled, toggle] = useToggle(false);

  const onClick = useCallback(() => {
    toggle();

    const nextUrl = getPaymentUrl(
      `${SITE_URL}/${language}/club/${club}${isSubscription ? `/subscription/payment/success` : `/event/payment/success`}`
    );

    onlinePayment({
      variables: {
        ...(isSubscription
          ? cardId
            ? { cardId: +cardId }
            : { templateId: +templateId, ...(promocode && { promocode }) }
          : visitId
            ? { visitId: +visitId }
            : { eventId: +eventId, ...(promocode && { promocode }) }),
        ...(!visitId && familyProfileId && { familyProfileId: +familyProfileId }),
        ...(!isSubscription && !visitId && options?.length && { options: JSON.stringify(options) }),
        nextUrl,
      },
      refetchQueries: ["ClientEventDetail"],
    }).then(
      ({ data: { onlinePayment } }) => {
        const {
          payment: { action, parameters },
        } = onlinePayment;

        fetch(`/api/createPayment?action=${action}`, {
          method: "POST",
          body: parameters,
        })
          .then((r) => r.json())
          .then(({ url }) => {
            // if (/throw_error/.test(url)) return;

            // window.open(url, "_blank", "noopener,noreferrer"); show as pop-up, blocked by safari
            router.push(url);
          })
          .catch((e) => console.log(e))
          .finally(toggle);
      },
      ({ graphQLErrors, message }) => {
        console.log(message);
        toggle();
        if (graphQLErrors?.[0]?.result == 5) return;

        const errorUrl = `${SITE_URL}/${language}/club/${club}${isSubscription ? `/subscription/payment/failed?${cardId ? `cardId=${cardId}` : `templateId=${templateId}`}` : `/event/payment/failed?eventId=${eventId}`}`;

        const asPath = `${SITE_URL}/${language}/club/${club}${isSubscription ? `/subscription/payment/failed` : `/event/payment/failed`}`;

        router.replace(errorUrl, asPath);
        // onResponse({ isSuccess: false, message });
      }
    );
  }, [templateId, familyProfileId, visitId, promocode, toggle]);

  return (
    <div className="payment-card__button-block" style={clas ? { maxWidth: "none", paddingRight: "10px", paddingLeft: "10px" } : {}}>
      <CardButton
        disabled={disabled}
        className={clsx({
          [clas]: clas,
          "payment-card__btn": !clas,
          "payment-card__btn--disabled": disabled,
        })}
        onClick={onClick}
        title={buttonTitle}
      />
    </div>
  );
};

export { PayByOnline };
